<!-- eslint-disable vue/no-unused-components -->
<template>
  <form
    @submit.prevent="handleAddNewCard"
    :class="$isProStudent && $vuetify.breakpoint.mdAndDown && 'add-form-mobile'"
  >
    <div :class="$isProStudent || 'tail'"></div>
    <div
      class="pa-1 x-mark d-flex"
      :class="$isProStudent && $vuetify.breakpoint.mdAndDown && 'mobile'"
    >
      <v-btn
        v-if="
          !$isProStudent || ($isProStudent && $vuetify.breakpoint.mdAndDown)
        "
        @click="$emit('minimize', hasChanged)"
        fab
        x-small
        color="rgb(230 230 239)"
      >
        <v-icon>mdi-window-minimize</v-icon>
      </v-btn>
      <v-btn fab x-small @click="$emit('close')" color="rgb(230 230 239)">
        <img src="@/assets/icons/cancel.svg" />
      </v-btn>
    </div>
    <v-container
      class="form-input-wrap overflow-scroll"
      :class="!$isProStudent || 'maxHeight100'"
    >
      <!-- SELECT CARD GROUP & CARD TYPE -->
      <v-row>
        <v-col
          v-if="isCreateProCard"
          md="4"
          sm="4"
          class="pb-0 px-1"
          :class="$isProStudent && $vuetify.breakpoint.mdAndDown && 'w-33'"
        >
          <v-select
            :items="PRO_FOLDER_LIST"
            item-text="folderName"
            item-value="folderId"
            v-model="selectedFolderId"
            label="Chọn thư mục"
            variant="solo"
          ></v-select>
        </v-col>
        <v-col
          :md="isCreateProCard ? 4 : 6"
          sm="4"
          class="pb-0 px-1"
          :class="$isProStudent && $vuetify.breakpoint.mdAndDown && 'w-33'"
        >
          <v-select
            :items="groupCardList"
            item-text="groupName"
            :item-value="isCreateProCard ? 'groupCardId' : 'id'"
            v-model="selectedGroupCardId"
            label="Chọn bộ thẻ"
            variant="solo"
          >
            <template v-if="isCreateProCard" v-slot:prepend-item>
              <input
                type="text"
                :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
                class="border-focus-blue ma-2 w-auto"
                v-model.trim="newProCardGroup"
                autoComplete
                placeholder="Nhập tên bộ thẻ..."
                @blur="newProCardGroupInputFocus = false"
                @focus="newProCardGroupInputFocus = true"
              />
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
        <v-col :md="isCreateProCard ? 4 : 6" sm="4" class="pb-0 px-1">
          <v-select
            :items="cardTypes"
            v-model="formValue.typeCard"
            label="Chọn kiểu thẻ"
            variant="solo"
          ></v-select>
        </v-col>
      </v-row>
      <!-- End of SELECT CARD GROUP & CARD TYPE -->

      <v-row class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Từ</h6>
          <div class="mt-1">
            <ckeditor
              :editor="editor.editorForWord"
              v-model="formValue.wordFormatted"
              :config="editorConfig"
              @ready="(editor) => onEditorReady(editor, 'word')"
            ></ckeditor>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="formValue.typeCard == 1" class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Phiên âm</h6>
          <div class="mt-1">
            <input
              type="text"
              :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
              class="border-focus-blue"
              v-model.trim="formValue.spelling"
              autoComplete
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Nghĩa</h6>
          <div class="mt-1">
            <ckeditor
              :editor="editor.editorForMean"
              v-model="formValue.meanFormatted"
              :config="editorConfig"
              @ready="(editor) => onEditorReady(editor, 'mean')"
            ></ckeditor>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="formValue.typeCard == 1" class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Audio</h6>
          <div class="mt-1">
            <v-file-input
              ref="fileInput"
              chips
              show-size
              truncate-length="25"
              accept="audio/mp3, audio/wav, audio/mp4, audio/ogg, audio/mpeg"
              label="Tải lên chỉ file audio"
              v-model="formValue.audio"
              @change="changeAudioFile"
            ></v-file-input>
          </div>
          <AudioRecorder
            v-if="$vuetify.breakpoint.smAndUp && $isProStudent"
            @stopRecord="stopRecord"
          />
          <div>
            <audio id="audio" controls>
              <source :src="audioLink" type="audio/mpeg" />
              <source :src="audioLink" type="audio/ogg" />
              <source :src="audioLink" type="audio/wav" />
              <source :src="audioLink" type="audio/mp4" />
              <source :src="audioLink" type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
            <p class="audio-name">{{ audioName }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="formValue.typeCard == 1" class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Từ loại</h6>
          <div class="mt-1">
            <input
              type="text"
              :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
              class="border-focus-blue"
              v-model.trim="formValue.wordType"
              autoComplete
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" md="12" class="pl-0">
          <h6>Ví dụ</h6>
          <div class="mt-1">
            <ckeditor
              :editor="editor.editorForExample"
              v-model="formValue.exampleFormatted"
              :config="editorConfig"
              @ready="(editor) => onEditorReady(editor, 'example')"
              class="textarea"
            ></ckeditor>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      class="d-flex"
      :class="{
        'ps-abs ml-0 w-100 bot-10':
          $isProStudent && $vuetify.breakpoint.mdAndDown,
        'btn-group-mobile': $vuetify.breakpoint.lgAndDown,
      }"
      style="margin-bottom: 10px"
    >
      <v-col logs="12" md="4" xs="3" class="pb-0 d-flex justify-center">
        <ButtonComponent
          :disable="!isActiveSubmitBtn"
          title="Thêm"
          type="submit"
        />
      </v-col>
      <v-col logs="12" md="4" xs="3" class="pb-0 d-flex justify-center">
        <ButtonComponent
          :disable="!isActiveSubmitBtn"
          title="Preview"
          @click="preview"
        />
      </v-col>
      <v-col logs="12" md="4" xs="3" class="pb-0 d-flex justify-center">
        <ButtonComponent title="History" @click="openHistory" />
      </v-col>
      <div v-if="showPreview || showHistory" class="ps-abs preview-pc">
        <CardPreviewContent v-if="showPreview" :currentCard="formValue" />
        <CardCreatingHistory v-if="showHistory" />
      </div>
    </v-row>
    <v-snackbar
      v-model="err"
      absolute
      centered
      tile
      color="red accent-2"
      multi-line
      vertical
    >
      Đã đạt giới hạn <strong>100 thẻ</strong> có thể được tạo.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="
            err = false;
            loading = false;
          "
        >
          Đóng
        </v-btn>
      </template>
    </v-snackbar>
  </form>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import CardPreviewContent from "@/components/CardPreviewContent.vue";
import CardCreatingHistory from "@/components/CardCreatingHistory.vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { formatBreakLineInEditor } from "@/uttil";
import { PRO_FOLDER_LIST, FIELDS_NEED_EDITOR } from "@/constants/index";
import AudioRecorder from "@/components/AudioRecorder.vue";

export default {
  name: "FormAddNewCard",
  components: {
    ButtonComponent,
    CardPreviewContent,
    AudioRecorder,
    CardCreatingHistory,
  },
  data() {
    return {
      loading: false,
      err: false,
      cardTypes: [
        { value: 1, text: "Kiểu 1" },
        { value: 2, text: "Kiểu 2" },
        { value: 3, text: "Kiểu 3" },
      ],
      selectedGroupCardId: this.currentGroupCardId,
      selectedFolderId: PRO_FOLDER_LIST[1].folderId,
      formValue: {
        typeCard:
          this.$isProStudent || this.$route.query.tab === "manage-pro-card-set"
            ? 2
            : 1,
        word: "",
        wordFormatted: "",
        spelling: null,
        mean: "",
        meanFormatted: "",
        audio: null,
        wordType: null,
        example: "",
        exampleFormatted: "",
      },
      defaultFormValue: {
        typeCard:
          this.$isProStudent || this.$route.query.tab === "manage-pro-card-set"
            ? 2
            : 1,
        word: "",
        wordFormatted: "",
        spelling: null,
        mean: "",
        meanFormatted: "",
        audio: null,
        wordType: null,
        example: "",
        exampleFormatted: "",
      },
      editor: {
        editorForWord: Editor,
        editorForMean: Editor,
        editorForExample: Editor,
      },
      editorInstance: {
        word: null,
        mean: null,
        example: null,
      },
      editorConfig: {},
      fieldsNeedEditor: FIELDS_NEED_EDITOR,
      audioName: "",
      audioLink: "",
      PRO_FOLDER_LIST,
      showPreview: false,
      showHistory: false,
      newProCardGroup: "",
      newProCardGroupInputFocus: false,
    };
  },
  props: {
    folderId: {
      type: [String, Number],
      default: "",
    },
    currentGroupCardId: {
      type: [String, Number],
      default: "",
    },
    currentStudentId: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    ...mapGetters("admin", ["getCardSets", "getAllProGroupCard"]),
    ...mapGetters("list", [
      "getListCardGroupInFolder",
      "studentGetAllProGroupCard",
    ]),
    ...mapState("admin", ["currentFolderId"]),
    isManageProCardSet() {
      return this.$route.query.tab === "manage-pro-card-set";
    },
    isCreateProCard() {
      return this.$isProStudent || this.isManageProCardSet;
    },
    groupCardList() {
      if (this.$isProStudent) {
        return (
          this.selectedFolderId
            ? this.getListCardGroupInFolder(this.selectedFolderId)
            : this.studentGetAllProGroupCard
        ).filter((item) =>
          item.groupName.toLowerCase().includes(this.newProCardGroup)
        );
      }
      return this.isManageProCardSet
        ? this.getAllProGroupCard(this.selectedFolderId, this.currentStudentId)
        : this.getCardSets;
    },
    isActiveSubmitBtn() {
      return (
        !!this.selectedGroupCardId &&
        (!this.isCreateProCard || !!this.selectedFolderId) &&
        !!this.formValue &&
        (!!this.formValue.wordFormatted ||
          !!this.formValue.meanFormatted ||
          !!this.formValue.exampleFormatted) &&
        !this.loading
      );
    },
    hasChanged() {
      return Object.keys(this.formValue).some(
        (field) => this.formValue[field] !== this.defaultFormValue[field]
      );
    },
  },
  watch: {
    hasChanged: {
      handler: function (hasChange) {
        return this.$emit("change", hasChange);
      },
      deep: true,
    },
    // currentStudentId: {
    //   handler: async function () {
    //     await this.fetchStudentCardGroupList({
    //       studentId: this.currentStudentId,
    //     });
    //   },
    // },
  },
  methods: {
    ...mapActions("card", ["createNewCard", "fetchAllCards", "getCardHistory"]),
    ...mapActions("admin", ["fetchCardSets"]),
    ...mapActions("student", ["studentCreateProCard"]),
    ...mapActions("list", ["fetchStudentCardGroupList"]),
    ...mapMutations("common", ["SET_DIALOG"]),
    preview() {
      this.fieldsNeedEditor.forEach((fieldName) => {
        this.formValue[`${fieldName}Formatted`] = formatBreakLineInEditor(
          this.formValue[`${fieldName}Formatted`]
        );
        this.formValue[fieldName] = formatBreakLineInEditor(
          viewToPlainText(
            this.editorInstance[fieldName].editing.view.document.getRoot()
          )
        );
      });
      this.showHistory = false;
      this.showPreview = !this.showPreview;
    },
    createNewCardGroup(folderId, newProCardGroup) {
      this.$emit("createNewCardGroup", {
        folderId,
        groupName: newProCardGroup,
      });
      this.newProCardGroup = "";
    },
    changeAudioFile() {
      let media = URL.createObjectURL(this.formValue.audio);
      let audio = document.getElementById("audio");
      audio.src = media;
      this.audioName = this.formValue.audio.name;
      this.audioLink = media;
    },
    onEditorReady(editor, fieldName) {
      this.editorInstance[fieldName] = editor;
    },
    async handleAddNewCard() {
      if (
        this.selectedGroupCardId &&
        (!this.isCreateProCard || this.selectedFolderId)
      ) {
        this.loading = true;
        this.fieldsNeedEditor.forEach((fieldName) => {
          this.formValue[`${fieldName}Formatted`] = formatBreakLineInEditor(
            this.formValue[`${fieldName}Formatted`]
          );
          this.formValue[fieldName] = formatBreakLineInEditor(
            viewToPlainText(
              this.editorInstance[fieldName].editing.view.document.getRoot()
            )
          );
        });
        let formData = new FormData();
        for (const field in this.formValue) {
          formData.append(field, this.formValue[field]);
        }
        formData.append("groupCardId", this.selectedGroupCardId);
        !this.isManageProCardSet
          ? await this.createNewCard(formData)
          : await this.studentCreateProCard(formData).catch((err) => {
              if (err.response.data.status_code == "401") {
                this.err = true;
                setTimeout(() => {
                  this.loading = false;
                }, 3000);
                return;
              }
            });
        if (this.err) return;
        this.formValue = {
          ...this.defaultFormValue,
          typeCard: this.formValue.typeCard,
        };
        this.audioName = "";
        this.audioLink = "";
        if (this.$isProStudent) {
          await this.$emit("onCardCreated");
          this.loading = false;
          return;
        }
        this.SET_DIALOG({
          description: "Thêm thành công",
          autoClose: true,
        });
        await this.fetchAllCards({
          id: this.selectedGroupCardId,
          isPro: this.isManageProCardSet,
        });
        await this.fetchCardSets(this.currentFolderId);
        this.loading = false;
        this.SET_DIALOG(false);
      }
    },
    async openHistory() {
      await this.getCardHistory(this.$route.params.studentId);
      this.showPreview = false;
      this.showHistory = !this.showHistory;
    },
    stopRecord(audio) {
      this.formValue.audio = audio.audioFile;
      this.changeAudioFile();
    },
  },
  mounted() {
    const handleKeyPressed = (e) => {
      if (this.showPreview || this.showHistory) {
        e.preventDefault();
        return;
      }
      if (e.key === "Enter") {
        if (e.ctrlKey) this.handleAddNewCard();
        else if (this.newProCardGroup && this.newProCardGroupInputFocus)
          this.createNewCardGroup(this.selectedFolderId, this.newProCardGroup);
      }
      if (e.key === "F3" || e.keyCode === 114) {
        e.preventDefault();
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          fileInput.$refs.input.click();
        }
      }
    };
    window.addEventListener("keydown", handleKeyPressed);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", handleKeyPressed);
    });
  },
};
</script>

<style lang="scss">
.w-auto {
  width: auto !important;
}
.w-100 {
  width: 100%;
}
.w-33 {
  width: 33.33%;
}
.bot-10 {
  bottom: 10px;
}
</style>

<style lang="scss" scoped>
::v-deep .v-list-item {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 300px) {
  .btn-group-mobile .col {
    ::v-deep .v-btn {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
      min-width: 90px !important;
    }
  }
}
.add-form-mobile {
  ::v-deep .v-select__selections {
    flex-wrap: nowrap;
    min-height: 32px;
    .v-select__selection--comma {
      margin-right: 0px;
    }
    input {
      display: none;
    }
  }
  ::v-deep .v-input__append-inner:first-of-type {
    display: none;
  }
}
.audio-name {
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 10px;
}

form {
  background: #c9c9d7 !important;
  border-radius: 28px;
  padding-bottom: 2px;
  display: block;
  position: relative;
  border: 2px solid transparent;

  .tail {
    position: absolute;
    left: 100.1px;
    transform: translateX(-50%);
    display: block;
    top: -12px;
    height: 12px;
    width: 100px;
    border-top: none;
    border-left: 52px solid transparent;
    border-bottom: 12px solid #c9c9d7;
    border-right: 52px solid transparent;
  }

  .x-mark {
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 12px;
    top: 3px;
    gap: 10px;
    padding-top: 0px !important;
    gap: 12px;
    &.mobile {
      img {
        width: 15px;
        height: 15px;
      }
    }
    img,
    .v-btn {
      min-width: 25px !important;
      height: 25px;
    }
    img {
      height: 10px;
    }
  }
}

.border-focus-blue:focus-visible {
  border-color: hsl(218, 81.6%, 56.9%) !important;
  outline: none !important;
}

.form-input-wrap {
  max-height: calc(100vh - 277px);
  margin-bottom: 10px;
  padding: 0 25px;
  margin-top: 26px;
  &.maxHeight100 {
    max-height: calc(100vh - 180px);
  }
}

@media (min-width: 300px) {
  ::v-deep .v-btn {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    min-width: 90px !important;
    &.audio-record-control {
      min-width: 40px !important;
      flex: 0 0 0;
    }
  }
}
@media (min-width: 600px) {
  ::v-deep .v-btn {
    min-width: 150px !important;
    &.audio-record-control {
      min-width: 40px !important;
    }
  }
}

.preview-pc {
  z-index: 100;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style lang="scss">
.ck.ck-editor__editable_inline {
  border: 1px solid #d9dfea !important;
  background-color: #f9fbfc;
  border-radius: 12px !important;

  &:focus-visible {
    border-color: hsl(218, 81.6%, 56.9%) !important;
    outline: none !important;
  }

  &.textarea {
    height: 120px;
  }
}

.overflow-scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #afacac;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a09e9e;
  }
}

.ck-content p {
  margin-bottom: 0;
}
</style>

<style lang="sass" scoped>
input
  width: 100%
  padding: 16px 0 16px 16px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  &.smd
    min-width: unset
</style>
